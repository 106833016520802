import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../shared/organization.service';


@Component({
  selector: 'app-body-home',
  templateUrl: './body-home.component.html',
  styleUrls: ['./body-home.component.css']
})
export class BodyHomeComponent implements OnInit {

  organizationSearch:any = "";
  onSearch:any;
  response:any = [];
  noOrganization : any;

  constructor(private organizationService: OrganizationService) { }

  ngOnInit() {
  }

  onSearchOrganization(){

    this.response = [];
    this.onSearch = true;
    let that = this;

if(this.organizationSearch !== "") {
    this.organizationService.findOrganization({"name":this.organizationSearch}).subscribe(
      res => {
      console.log(res); 
      if(res['organization'].length !== 0) {
        this.noOrganization = false;
        this.response = res['organization'];     
        that.onSearch = false;          
      } else {
        this.onSearch = false;
        this.noOrganization = true;
      }
      
    },
    err => {
        this.onSearch = false;
        console.log(err);
    });
} else {
  this.onSearch = false;
}
    // setTimeout(function () {
    //   that.response.push({
    //     name:'ASSO BLEUETS'
    //   });
    //   
    // }, 5000);

  //  
  

  }

  onKey(event) {
    const inputValue = event.target.value;
    this.organizationSearch = inputValue;
  }

}
