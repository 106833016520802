export class StripeCheckout {

title: string;
description: string;
logo: string;
icon: string;
organization: string;
stripe: string;
campaign: string;
amount: any = 25;
idKiosk: string;
success_url:any;
cancel_url:any;

}
