import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-donation',
  templateUrl: './online-donation.component.html',
  styleUrls: ['./online-donation.component.css']
})
export class OnlineDonationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



}
