import { Component, OnInit } from '@angular/core';
import { KioskService } from '../../shared/kiosk.service';

import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-success-donation',
  templateUrl: './success-donation.component.html',
  styleUrls: ['./success-donation.component.css']
})
export class SuccessDonationComponent implements OnInit {

  color:any;
  linkdonation:any;

  constructor(private kioskService:KioskService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    

    this.linkdonation = this.route.snapshot.paramMap.get('id');

    this.kioskService.getKioskWebByOrganization(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
         
        console.log(res);
        this.color = res['kiosk'][0].template[0].color;

      },
      err => {
        // // this.webParam.email = 'assistance@e6kdonation.com';
        // this.error = true;
        // this.router.navigate(['don/organization/notfound']);
      });


  }

  navToCampaign(){
    
  }

}
