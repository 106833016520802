import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { OnlineDonationComponent } from './online-donation/online-donation.component';
import { GetOrganizationComponent } from './get-organization/get-organization.component';
import { AmountCampaignComponent } from './online-donation/amount-campaign/amount-campaign.component';
import { CheckoutdonationComponent } from './online-donation/checkoutdonation/checkoutdonation.component';
import { PaymentMethodTypesComponent } from './online-donation/payment-method-types/payment-method-types.component';
import { OrganizationCampaignComponent } from './online-donation/organization-campaign/organization-campaign.component';
import { DonorInformationComponent } from './online-donation/donor-information/donor-information.component';
import { NotFoundComponent } from './home/not-found/not-found.component';
import { SuccessDonationComponent } from './online-donation/success-donation/success-donation.component';

import { UserSignInComponent } from './users/user-sign-in/user-sign-in.component';
import { UserSignUpComponent } from './users/user-sign-up/user-sign-up.component';
import { SuccessRegistrationComponent } from './users/success-registration/success-registration.component';
import { ConfirmRegistrationComponent } from './users/confirm-registration/confirm-registration.component';
import { UserSendResetPasswordComponent } from './users/user-send-reset-password/user-send-reset-password.component';
import { UserResetPasswordComponent } from './users/user-reset-password/user-reset-password.component';
import { DeleteUserDonorComponent } from './users/delete-user-donor/delete-user-donor.component';
// import { DashboardDonatorComponent } from './dashboard-donator/dashboard-donator.component';
import { DashboardDonorComponent } from './dashboard-donor/dashboard-donor.component';
// import { HomeDonatorComponent } from './dashboard-donor/home-donator/home-donator.component';
import { HomeDonorComponent } from './dashboard-donor/home-donor/home-donor.component';
// import { SimulatorTaxesDonatorComponent } from './dashboard-donor/simulator-taxes-donator/simulator-taxes-donator.component';
import { SimulatorTaxesDonorComponent } from './dashboard-donor/simulator-taxes-donor/simulator-taxes-donor.component';

import { TransactionDonorComponent } from './dashboard-donor/transaction-donor/transaction-donor.component';
import { TransactionDonorOrgaComponent } from './dashboard-donor/transaction-donor-orga/transaction-donor-orga.component';
// import { ReadTransactionDonatorComponent } from './dashboard-donor/read-transaction-donator/read-transaction-donator.component';
import { ReadTransactionDonorComponent } from './dashboard-donor/read-transaction-donor/read-transaction-donor.component';
import { FiscalReceiptDonatorComponent } from './dashboard-donor/fiscal-receipt-donator/fiscal-receipt-donator.component';
import { ConfirmRegistrationCodeComponent } from './dashboard-donor/confirm-registration-code/confirm-registration-code.component';

import { CampaignsOrganizationComponent } from './online-donation/campaigns-organization/campaigns-organization.component';

import { CgvComponent } from './cgv/cgv.component';

// *****************************
// *****************************
import { HomeComponent } from './home/home.component';
import { ContactHomeComponent } from './home/contact-home/contact-home.component';

export const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'contact', component: ContactHomeComponent
  },
  {
    path: 'cgv', component: CgvComponent
  },  
  // {
  //   path: 'don/:asso', component: CheckoutdonationComponent
  // },
  {
    path: 'don/payment/method/:asso', component: PaymentMethodTypesComponent
  },
  {
    path: 'sucess/:id', component: SuccessDonationComponent
  },

  {
    path: 'don/:id', component: OnlineDonationComponent,
    children: [
       { path: '', component: CampaignsOrganizationComponent},
      //  { path: 'payment/amount/:asso/:title/:description/:logo/:icon/:organisation/:stripe/:campaign', component: AmountCampaignComponent},

       { path: 'payment/amount/:organizationLink/:organizationId/:campaignId', component: AmountCampaignComponent},
      //  { path: 'sucess/:organizationLink', component: SuccessDonationComponent},
       { path: 'payment/donor/information/:organizationLink/:organizationId/:campaignId', component: DonorInformationComponent},
      //  { path: 'payment/donor/information/:asso/:title/:description/:logo/:icon/:organisation/:stripe/:campaign/:amount', component: DonorInformationComponent},
       { path: 'payment/method', component: PaymentMethodTypesComponent},
       { path: 'method/:title/:image/:desc/:asso/:idO/:idS/:amount/:campaign', component: CheckoutdonationComponent}
              ]
  },
  {
    path: 'dashboard/donor', component: DashboardDonorComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeDonorComponent,canActivate: [AuthGuard]},
      { path: 'simulator', component: SimulatorTaxesDonorComponent,canActivate: [AuthGuard]},
      { path: 'transaction', component: TransactionDonorComponent,canActivate: [AuthGuard]},
      { path: 'transaction/org/:id', component: TransactionDonorOrgaComponent,canActivate: [AuthGuard]},
      { path: 'transaction/:id', component: ReadTransactionDonorComponent,canActivate: [AuthGuard]},      
      { path: 'transaction/fiscal/receipt/:id',component:FiscalReceiptDonatorComponent,canActivate: [AuthGuard]},        
             ]
  },
  { path: 'donor/connect/confirm/code/:token', component: ConfirmRegistrationCodeComponent},

  { path: 'donor/connect', component: UserSignInComponent},
  { path: 'donor/register', component: UserSignUpComponent},
  { path: 'donor/register/success', component: SuccessRegistrationComponent},
  { path: 'donor/register/validation/:token', component: ConfirmRegistrationComponent},
  { path: 'donor/reset/password/send', component: UserSendResetPasswordComponent},
  { path: 'donor/reset/password/init/:token', component: UserResetPasswordComponent},
  { path: 'donor/delete/:token', component: DeleteUserDonorComponent}



  
  ,
  {
    path: 'don/organization/notfound', component: NotFoundComponent,
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
