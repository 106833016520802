export class User {
    _id: string;
    fullName: string;
    email: string;
    password: string;
    role: [
        {
          name: String,
          description: String
        }
    ];
    address: {
      firstName: string,
      lastName: string,
      entreprisename: string,
      address: string,
      code_postal: string,
      city: string,
      state: string,
    };
    organisation: String;
    active: Boolean;
    profil: Boolean;
    valideId: String;
    accountDetailActive: Boolean;
    idOrganisation: String;
}
