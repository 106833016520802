import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-organization',
  templateUrl: './get-organization.component.html',
  styleUrls: ['./get-organization.component.css']
})
export class GetOrganizationComponent implements OnInit {
  template: any;
  campaignArray: any;
  showCampaign: boolean;
  constructor() { }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

  ngOnInit() {

  this.showCampaign = true;
    this.template = {
      nameColor: '',
      color: '#7c62ab',
      colorActive: '#734088',
    };

    this.campaignArray = [
      {
        title: 'Contre le COVID-19',
        description: '',
        icon: 'icon10',
        color: 'purple',
      },
      {
        title: 'Contre ALZHEIMER',
        description: '',
        icon: 'icon21',
        color: 'purple',
      },
      {
        title: 'NOTRE DEME DE PARIS',
        description: '',
        icon: 'icon28',
        color: 'purple',
      },
      {
        title: 'UN PUIT EN AFFRIQUE',
        description: '',
        icon: 'icon13',
        color: 'purple',
      },
    ];


  }

  makePaiment() {
    this.showCampaign = false;
  }

  backcampaign() {
    this.showCampaign = true;
  }


  createSessionStripeCheckout() {


  }
}
