import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  getLastTransactions(id) {
    return this.http.get(environment.apiBaseUrl + '/online/transaction/organization/10/' + id);
  } 
    
  getLastTransactionsOrganization(id) {
    return this.http.get(environment.apiBaseUrl + '/online/transactions/organization/' + id);
  } 

  getTransactionByDonator(id) {
    return this.http.get(environment.apiBaseUrl + '/online/transactions/donator/' + id);
  }

  getTransactionDonatorById(id) {
    return this.http.get(environment.apiBaseUrl + '/online/transaction/donator/' + id);
  }

  getTransactionDonatorByOrg(id) {
    return this.http.get(environment.apiBaseUrl + '/online/transaction/organization/donator/:id' + id);
  }

  sendReceiptTransaction(transaction) {
    if (transaction !== undefined && transaction !== null){
      var url = environment.apiBaseUrl + '/transaction/organization/send/receipt/' + transaction.idStripe;
      return this.http.post(url, transaction);
    }
   
  }


}
