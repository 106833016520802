import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class OrganizationService {
  
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  
  constructor(private http: HttpClient) { }
  
  findOrganization(name) {
    return this.http.post(environment.apiBaseUrl + '/online/find/organization', name);
  }
  
  }
  