import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { User } from '../../shared/user.model'
import { UserService } from '../../shared/user.service'
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-confirm-registration-code',
  templateUrl: './confirm-registration-code.component.html',
  styleUrls: ['./confirm-registration-code.component.css']
})
export class ConfirmRegistrationCodeComponent implements OnInit {

  user:User;
  code:any;
  token:any;  
  email:any;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  requiredCode:any;
  codeSending:any;
  errorSendind:any;
  errorCode:any;
  lockButton:any = 5;
  apiFailed:any = false;
  loadPage:any = false;
  
  
  constructor(private userService:UserService, private router: Router, private route: ActivatedRoute) {
    this.user = new User();
   }

  ngOnInit() {
     this.token = this.route.snapshot.paramMap.get("token");
    //  var decoded = jwt_decode(this.token); 
    //  this.email = decoded['id'];
    //  console.log(this.email);
    //  if(this.email){
    //    alert('null')
    //  }
    //  console.log(decoded['id']);
     
     //Get user by token 
     this.userService.verifTokenUser(this.route.snapshot.paramMap.get("token")).subscribe(
      res => {                
        this.email = res['email'];        
      },
      err => {
        this.apiFailed = true;    
        //this.serverErrorMessages = err.error.message;
      }
    );     
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  activeAccount() {
    let that  =this;
    if(!this.code){      
      this.requiredCode = true;
      setTimeout(function(){       
        that.requiredCode = false;            
       }, 3000);  
    }
    this.userService.activeUserDonor(this.code,this.token).subscribe(
      res => {          
        this.loadPage = true;
        setTimeout(function(){       
          that.router.navigateByUrl('/donor/connect');                    
         }, 3000);     
        
      },
      err => {        
        this.errorCode = true;
        setTimeout(function(){       
          that.errorCode = false;            
         }, 3000);    
        // this.serverErrorMessages = err.error.message;
      }
    );
  }


  sendCodeUser() {
    let that = this;
    this.lockButton --;
    if(this.lockButton !== 0){
      this.userService.sendCodeUser(this.token).subscribe(
        res => {
          this.codeSending = true;
          setTimeout(function(){       
            that.codeSending = false;            
           }, 3000);                   
        },
        err => {
            this.errorSendind = true;
            setTimeout(function(){       
              that.errorSendind = false;            
             }, 3000);  
        });
    } else {
      this.router.navigateByUrl('/'); 
    }
  }


}
