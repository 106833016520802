import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { from } from 'rxjs';
import { UserService } from '../../shared/user.service'

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.css']
})
export class UserSignInComponent implements OnInit {

public formModel: FormModel = {};
private siteKey : string = '6LdbDbAZAAAAAEaTv6aWBzblaP6tlaA0iXqKCGp0';
private siteKey2 : string = '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD';
key : string = "6LdbDbAZAAAAAHOID_0WA3dnRNh2ZjOURCuPyG7e";

model = {
  email: '',
  password: ''
};

serverErrorMessages: string;
recaptcha : any[];
captchaControl2 : boolean;

emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(private userService:UserService, private router: Router) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    // console.log(form);
    this.userService.login(form.value).subscribe(
      res => {      
        console.log(res);
        if(res['active'] === false) {
          this.userService.sendCodeUser(res['encodeValidation']).subscribe(
            res => {                   
            },
            err => {                
            });
          this.router.navigateByUrl('/donor/connect/confirm/code/'+res['encodeValidation']);              
        } else {
          this.userService.setToken(res['token']);                          
          this.router.navigateByUrl('/dashboard/donor');      
        }
          
      },
      err => {
        this.serverErrorMessages = err.error.message;
      }
    );

  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
