import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-method-types',
  templateUrl: './payment-method-types.component.html',
  styleUrls: ['./payment-method-types.component.css']
})
export class PaymentMethodTypesComponent implements OnInit {

  template: any;
  myStyle: any;
  constructor() { }

  ngOnInit() {
    this.template = {
      nameColor: '',
      color: '#204468',
      colorActive: '#734088',
    };
  }




}
