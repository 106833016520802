import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from './user.model';
// import { Organisation } from './organisation.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  selectedUser = {
    fullName: '',
    email: '',
    password: ''
    };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  // constructor(private http: HttpClient) { }
  constructor(private http: HttpClient) { }

  //HttpMethods

  registerDonor(user) {
    return this.http.post(environment.apiBaseUrl + '/online/user/register', user, this.noAuthHeader);
  }

  activeUserDonor(code,token) {
    return this.http.get(environment.apiBaseUrl + '/online/user/active/account/' + token + '/' + code, this.noAuthHeader);
  }

  sendLinkConfirmationEmail(id) {
    return this.http.post(environment.apiBaseUrl + '/online/user/send/confirmation', id, this.noAuthHeader);
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/online/authenticate', authCredentials, this.noAuthHeader);
  }

  getEmailUserByEmail(token) {
    return this.http.get(environment.apiBaseUrl + '/online/user/email/account/' + token, this.noAuthHeader);
  }

  verifTokenUser(token) {
    return this.http.get(environment.apiBaseUrl + '/online/user/verif/token/' + token, this.noAuthHeader);
  }

  sendCodeUser(token) {
    return this.http.get(environment.apiBaseUrl + '/online/user/send/code/' + token, this.noAuthHeader);
  }

  sendEmailResetPassword(email) {
    return this.http.get(environment.apiBaseUrl + '/online/user/reset/password/email/' + email, this.noAuthHeader);
  }

  initPasswordDonor(token) {
    return this.http.post(environment.apiBaseUrl + '/online/user/password/init', token, this.noAuthHeader); 
  }

  deleteUserDonor(token) {
    let object = {
      token: token
    }
    return this.http.post(environment.apiBaseUrl + '/online/user/delete', object, this.noAuthHeader); 
  }
  getDonatorAddress(sEmail){
    return this.http.get(environment.apiBaseUrl + '/rf/5/' + sEmail);
  }

  getSendReceiptFiscal(sId,oData){
    return this.http.post(environment.apiBaseUrl + '/rf/6/' + sId,oData);
  }
/*************************************************************************************************************/
/*************************************************************************************************************/
/*************************************************************************************************************/

  postUserAdmin(user) {
    return this.http.post(environment.apiBaseUrl + '/user/admin/register', user);
  }

  

  disableAccount(id){
    return this.http.post(environment.apiBaseUrl + '/user/account/disable', id);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile');
    // return this.http.get(environment.apiBaseUrl + '/dashboard');
  }

  // getOrganisationByUserId(id) {
  //   return this.http.get(environment.apiBaseUrl + '/user/organisation/' + id);
  // }

  activeUserAccount(id: string) {
    let objectid = {
      confirmId: id
    };
    return this.http.post(environment.apiBaseUrl + '/activeAccount', objectid, this.noAuthHeader);
  }

  updatePassword(objectUser){
     return this.http.post(environment.apiBaseUrl + '/updatepassword', objectUser);
  }

  updatePasswordIdCheck(objectUser : any,id : String){
    return this.http.post(environment.apiBaseUrl + '/user/password/update/'+id, objectUser);
 }

  //Helper Methods

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else {
      return null;
    }
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  initPasswordUser(email) {
    return this.http.post(environment.apiBaseUrl + '/user/password/init', {id: email}, this.noAuthHeader);
  }

  controlIdUser_old(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }

  controlIdUser(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }


}
