import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { UserService } from '../../shared/user.service';

export interface FormModel {
  captcha?: string;
}


@Component({
  selector: 'app-user-send-reset-password',
  templateUrl: './user-send-reset-password.component.html',
  styleUrls: ['./user-send-reset-password.component.css']
})
export class UserSendResetPasswordComponent implements OnInit {
  
  public formModel: FormModel = {};
  private siteKey : string = '6LdbDbAZAAAAAEaTv6aWBzblaP6tlaA0iXqKCGp0';
  private siteKey2 : string = '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD';
  key : string = "6LdbDbAZAAAAAHOID_0WA3dnRNh2ZjOURCuPyG7e";

  serverErrorMessages: string;
  recaptcha : any[];
  captchaControl2 : boolean;
  emailDonor:any;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  initPassword:any;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit(form: NgForm) {
    console.log(form.value);
    this.userService.sendEmailResetPassword(form.value.email).subscribe(
      res => {              
        console.log(res);
        this.initPassword = true;        
        form.reset();
      },
      err => {
        this.serverErrorMessages = err.error.message;
      }
    );
  }

}
