import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";


@Component({
  selector: 'app-delete-user-donor',
  templateUrl: './delete-user-donor.component.html',
  styleUrls: ['./delete-user-donor.component.css']
})
export class DeleteUserDonorComponent implements OnInit {

  token:any;
  apiFailed:any;

  linkExpired:any = false;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get("token");     
    //Get user by token 
    this.userService.verifTokenUser(this.route.snapshot.paramMap.get("token")).subscribe(
     res => {  
       console.log(res);
        if(res['tokenExpired'] === true) {
          this.linkExpired = true;
        } else {
          this.deleteUserDonor();
        }
       this.apiFailed = false;        
     },
     err => {        
      console.log(err);
       this.apiFailed = true;    
       //this.serverErrorMessages = err.error.message;
     }
   );    
 }

 deleteUserDonor() {
   console.log(this.token);
  this.userService.deleteUserDonor(this.token).subscribe(
    res => {       
      this.apiFailed = false; 
           
    },
    err => {             
      this.apiFailed = true;    
      //this.serverErrorMessages = err.error.message;
    }
  );
 }

}
