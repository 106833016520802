import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ValidateEqualModule } from 'ng-validate-equal';
import { routes } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';

import { FormBuilder, FormGroup, Validators, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RecaptchaFormsModule,RecaptchaModule } from 'ng-recaptcha';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GetOrganizationComponent } from './get-organization/get-organization.component';
import { CheckoutdonationComponent } from './online-donation/checkoutdonation/checkoutdonation.component';
import { PaymentMethodTypesComponent } from './online-donation/payment-method-types/payment-method-types.component';
import { OnlineDonationComponent } from './online-donation/online-donation.component';
import { OrganizationCampaignComponent } from './online-donation/organization-campaign/organization-campaign.component';
import { AmountCampaignComponent } from './online-donation/amount-campaign/amount-campaign.component';
import { HeaderComponent } from './online-donation/header/header.component';
import { FooterComponent } from './online-donation/footer/footer.component';
import { DonorInformationComponent } from './online-donation/donor-information/donor-information.component';
import { NotFoundComponent } from './home/not-found/not-found.component';
import { SuccessDonationComponent } from './online-donation/success-donation/success-donation.component';
import { CampaignsOrganizationComponent } from './online-donation/campaigns-organization/campaigns-organization.component';
/** Auth & Registration user **/
import { UserSignInComponent } from './users/user-sign-in/user-sign-in.component';
import { UserSignUpComponent } from './users/user-sign-up/user-sign-up.component';
import { SuccessRegistrationComponent } from './users/success-registration/success-registration.component';
import { ConfirmRegistrationComponent } from './users/confirm-registration/confirm-registration.component';
import { ConfirmRegistrationCodeComponent } from './dashboard-donor/confirm-registration-code/confirm-registration-code.component';
import { DeleteUserDonorComponent } from './users/delete-user-donor/delete-user-donor.component';
/** Dasboard Donor **/
import { DashboardDonorComponent } from './dashboard-donor/dashboard-donor.component';
import { HeaderDonorComponent } from './dashboard-donor/header-donor/header-donor.component';
import { HomeDonorComponent } from './dashboard-donor/home-donor/home-donor.component';
import { TransactionDonorComponent } from './dashboard-donor/transaction-donor/transaction-donor.component';
import { ReadTransactionDonorComponent } from './dashboard-donor/read-transaction-donor/read-transaction-donor.component';
import { SimulatorTaxesDonorComponent } from './dashboard-donor/simulator-taxes-donor/simulator-taxes-donor.component';
import { FiscalReceiptDonatorComponent } from './dashboard-donor/fiscal-receipt-donator/fiscal-receipt-donator.component';
import { UserResetPasswordComponent } from './users/user-reset-password/user-reset-password.component';
import { UserSendResetPasswordComponent } from './users/user-send-reset-password/user-send-reset-password.component';
import { HomeComponent } from './home/home.component';
import { HeaderHomeComponent } from './home/header-home/header-home.component';
import { BodyHomeComponent } from './home/body-home/body-home.component';
import { FooterHomeComponent } from './home/footer-home/footer-home.component';
import { ContactHomeComponent } from './home/contact-home/contact-home.component';
import { CgvComponent } from './cgv/cgv.component';
import { CommonModule } from '@angular/common';  
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransactionDonorOrgaComponent } from './dashboard-donor/transaction-donor-orga/transaction-donor-orga.component';




registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    
    AppComponent,
    GetOrganizationComponent,
    CheckoutdonationComponent,
    PaymentMethodTypesComponent,
    OnlineDonationComponent,
    OrganizationCampaignComponent,
    AmountCampaignComponent,
    HeaderComponent,
    FooterComponent,
    DonorInformationComponent,
    NotFoundComponent,
    SuccessDonationComponent,
    UserSignInComponent,
    UserSignUpComponent,
    FiscalReceiptDonatorComponent,
    SuccessRegistrationComponent,
    ConfirmRegistrationComponent,
    ConfirmRegistrationCodeComponent,
    DashboardDonorComponent,
    TransactionDonorComponent,
    SimulatorTaxesDonorComponent,
    ReadTransactionDonorComponent,
    HomeDonorComponent,
    HeaderDonorComponent,
    UserResetPasswordComponent,
    UserSendResetPasswordComponent,
    DeleteUserDonorComponent,
    HomeComponent,
    HeaderHomeComponent,    
    BodyHomeComponent,
    FooterHomeComponent,
    ContactHomeComponent,
    CampaignsOrganizationComponent,
    CgvComponent,
    TransactionDonorOrgaComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(),
    RouterModule.forRoot(routes),
    RecaptchaFormsModule,
    ValidateEqualModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
