import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KioskService {

noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

constructor(private http: HttpClient) { }


getKioskWebByOrganization(id) {
  return this.http.get(environment.apiBaseUrl + '/online/kiosk/web/' + id);
}
}
