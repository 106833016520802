import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '../../shared/campaign.service';

declare var Stripe; // : stripe.StripeStatic;


@Component({
  selector: 'app-checkoutdonation',
  templateUrl: './checkoutdonation.component.html',
  styleUrls: ['./checkoutdonation.component.css']
})
export class CheckoutdonationComponent implements OnInit {
  template: any;
  campaignArray: any;
  showCampaign: boolean;
  organizationName: any;
  stripe; // : stripe.Stripe;

  constructor(private route: ActivatedRoute, private campaignService: CampaignService) { }
  ngOnInit() {
    this.organizationName =   this.route.snapshot.paramMap.get('asso');
    this.showCampaign = true;

    this.template = {
      nameColor: '',
      color: '#204468',
      colorActive: '#734088',
    };


    this.campaignArray = [
      {
        title: 'Contre le COVID-19',
        description: '',
        icon: 'icon10',
        color: 'purple',
        don: '1050'
      },
      {
        title: 'Contre ALZHEIMER',
        description: '',
        icon: 'icon21',
        color: 'purple',
        don: '15'
      },
      {
        title: 'NOTRE DEME DE PARIS',
        description: '',
        icon: 'icon28',
        color: 'purple',
        don: '50'
      },
      {
        title: 'UN PUIT EN AFFRIQUE',
        description: '',
        icon: 'icon13',
        color: 'purple',
        don: '100'
      },
      {
        title: 'UN PUIT EN AFFRIQUE',
        description: '',
        icon: 'icon13',
        color: 'purple',
        don: '100'
      },
      {
        title: 'UN PUIT EN AFFRIQUE',
        description: '',
        icon: 'icon13',
        color: 'purple',
        don: '100'
      },
    ];


  }

  makePaiment() {
    this.showCampaign = false;
  }

  backcampaign() {
    this.showCampaign = true;
    this.campaignArray = [
      {
        title: 'Contre le COVID-19',
        description: '',
        icon: 'icon10',
        color: 'purple',
      },
      {
        title: 'Contre ALZHEIMER',
        description: '',
        icon: 'icon21',
        color: 'purple',
      },
      {
        title: 'NOTRE DEME DE PARIS',
        description: '',
        icon: 'icon28',
        color: 'purple',
      },
      {
        title: 'UN PUIT EN AFFRIQUE',
        description: '',
        icon: 'icon13',
        color: 'purple',
      },
    ];

  }


  // createSessionStripeCheckout() {
  //   this.campaignService.createSessionStripeCheckout().subscribe(
  //     res => {




  //       console.log(res['session'].id);
  //       const sessionId = res['session'].id;

  //       // this.stripe = Stripe('pk_test_your_key');

  //       const stripe = Stripe('pk_test_KmiSL35NVxX8h8qrWQWm5L0h', {
  //         stripeAccount: 'acct_1GBIHWBRuv9WoXh1'
  //       });

  //       stripe.redirectToCheckout({
  //         // Make the id field from the Checkout Session creation API response
  //         // available to this file, so you can provide it as parameter here
  //         // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
  //         sessionId: sessionId
  //       }).then(function (result) {
  //         // If `redirectToCheckout` fails due to a browser or network
  //         // error, display the localized error message to your customer
  //         // using `result.error.message`.
  //       });


  //       // document.location.href = 'https://e6kdonation.com/checkout/stripe.html?stripe=pk_test_KmiSL35NVxX8h8qrWQWm5L0h&stripeaccount=acct_1GBIHWBRuv9WoXh1&id='+sessionId;
  //     },
  //     err => {
  //         console.log(err);
  //     });
  // }




}
