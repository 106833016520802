import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CampaignService } from '../../shared/campaign.service';
import { TransactionService } from '../../shared/transaction.service';
import { WebParam } from '../../shared/webParam.model';

import { OwlOptions } from 'ngx-owl-carousel-o';

// declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-organization-campaign',
  templateUrl: './organization-campaign.component.html',
  styleUrls: ['./organization-campaign.component.css']
})
export class OrganizationCampaignComponent implements OnInit {

  webParam: WebParam;
  transactionArray: any;
  error: boolean;
  actif: boolean;
  // stripe; // : stripe.Stripe;

  nameColor: any;
  color: any;
  colorActive: any;

  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 2000,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText:  [ '<button type="button" role="presentation" class="owl-next"><i class="icon-angle-left"></i></button>', '<button type="button" role="presentation" class="owl-next"><i class="icon-angle-right"></i></button>' ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  };

  constructor(private route: ActivatedRoute, private campaignService: CampaignService,  private router: Router,
              private transactionService: TransactionService) {
              this.webParam = new WebParam();
               }


  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.loadExternalScript('assets/js/functions.js');
  }
  ngOnInit(
    
  ) {
this.webParam.linkDonation =   this.route.snapshot.paramMap.get('id');

this.transactionService.getLastTransactionsOrganization(this.webParam.linkDonation).subscribe(
  res => {
    this.error = false;
    // tslint:disable-next-line: no-string-literal
    this.transactionArray = res['transactions'];
  },
  err => {
      this.error = true;
  });

/**
 * Todo 2 - Get all campaign afiliate on this organization
 */
this.campaignService.findCampaignsByOrganization(this.webParam.linkDonation).subscribe(
      res => {
        
          // tslint:disable-next-line: no-string-literal
          this.webParam = res['organization'];          
          console.log(this.webParam);
        
          this.actif = this.webParam.kiosk.actif;
          this.nameColor = this.webParam.kiosk.template.nameColor;
          this.color = this.webParam.kiosk.template.color;
          this.colorActive = this.webParam.kiosk.template.colorActive;
          const tempCampaignArray = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.webParam.campaigns.length; i++) {
          const element = this.webParam.campaigns[i];
          if (element.actif === true) {
          tempCampaignArray.push(element);
          }
          }
          
          this.webParam.campaigns = tempCampaignArray;
          console.log(this.webParam.campaigns[0].title);
          this.error = false;
      },
      err => {
        this.webParam.email = 'assistance@e6kdonation.com';
        this.error = true;
        // this.router.navigate(['don/organization/notfound']);
      });
  }

  navToAmount(campaign) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['don/' + this.webParam.linkDonation + '/payment/amount/' + this.webParam.linkDonation + '/' + campaign.idOrganization +'/' + campaign._id]);
  }
}
