import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simulator-taxes-donor',
  templateUrl: './simulator-taxes-donor.component.html',
  styleUrls: ['./simulator-taxes-donor.component.css']
})
export class SimulatorTaxesDonorComponent implements OnInit {

  amount: any = 0;

  discountCompany: Number = 0;
  discountWorker: Number = 0;
  discountEstate: Number = 0;

  array: any = [
    {
      'name': '',
      'nb': '1.jpg',
    },
    {
      'name': '',
      'nb': '2.jpg',
    },
    {
      'name': '',
      'nb': '3.jpg',
    },
    {
      'name': '',
      'nb': '3.jpg',
    }
  ]

  constructor() { }

  ngOnInit() {
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keyPadDectec() {
    this.calculTaxes();
  }

calculTaxes() {

  let taxesCompany = Number(((this.amount * 60) / 100).toFixed(2));
  let taxesWorker = Number(((this.amount * 66) / 100).toFixed(2));
  let taxesEstate = Number(((this.amount * 77) / 100).toFixed(2));

  this.discountCompany = this.amount - taxesCompany;
  this.discountWorker = this.amount - taxesWorker;
  this.discountEstate = this.amount - taxesEstate;
  
  this.discountCompany = Number(this.discountCompany.toFixed(2));
  this.discountWorker = Number(this.discountWorker.toFixed(2));
  this.discountEstate = Number(this.discountEstate.toFixed(2));
  
}

eachTemplate() {
this.array = [
  {
    'name': '',
    'nb': '3.jpg',
  },
  {
    'name': '',
    'nb': '2.jpg',
  },
  {
    'name': '',
    'nb': '2.jpg',
  },
  {
    'name': '',
    'nb': '3.jpg',
  }
]
}

}
