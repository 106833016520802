import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../../shared/user.service'

@Component({
  selector: 'app-header-donor',
  templateUrl: './header-donor.component.html',
  styleUrls: ['./header-donor.component.css']
})
export class HeaderDonorComponent implements OnInit {

  constructor(private userService:UserService, private router: Router) { }

  ngOnInit() {
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  
  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/donor/connect']);
  }

}
