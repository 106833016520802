export class WebParam {

  // title: string;
  // description: string;
  // logo: string;
  // icon: string;
  // organization: string;
  // stripe: string;
  // campaign: string;
  // amount: any = 25;
  // success_url:any;
  // cancel_url:any;

  idOrganization: any;
  organizationName: any;
  linkDonation: any;
  id_connected_account: any;
  email: any;
  hoverColorCampaign: boolean;
  hoverColorUserConnection: boolean;
  hoverColorUserRegistration: boolean;
  kiosk: {
    id: any,
    actif: boolean,
    template: {
        nameColor: any,
        color: any,
        colorActive: any,
              }
  };
  campaigns: any;
  campaign: any;
  }
