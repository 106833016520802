export class Transaction {
    _id: string;
    id: string;
    amount:any;
    description:any;
    email:any;
    date:any;
    enableReceipt : Boolean;
    payment_method_details: {
            card : {
                brand:any;
                exp_month:any;
                exp_year:any;                                                
                last4:any;
                network:any;
            }
    
    }
}


