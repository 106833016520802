import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

constructor(private http: HttpClient) { }


donCampaignOrganization(id) {
  return this.http.get(environment.apiBaseUrl + '/don/organisation/campaign/' + id);
}

organizationExist(id, stripe, organization) {
  return this.http.get(environment.apiBaseUrl + '/don/organization/exist/' + id + '/' + stripe + '/' + organization);
}

campaignExist(campaign, title, description) {
  return this.http.get(environment.apiBaseUrl + '/don/campaign/exist/' + campaign + '/' + title + '/' + description);
}

findCampaignsByOrganization(id) {
  return this.http.get(environment.apiBaseUrl + '/online/campaigns/organization/' + id);
}

findCampaignByOrganization(idOrganization, idCampaign) {
  return this.http.get(environment.apiBaseUrl + '/online/campaign/organization/' + idOrganization + '/' + idCampaign);
}

organizationByIdAndCammpaignById(idOrg, idCam){
  return this.http.get(environment.apiBaseUrl + '/online/organization/campaign/' + idOrg + '/' + idCam);
}

getCampaignById(id) {
}

// createSessionStripeCheckout() {
//   return this.http.get(environment.apiBaseUrl + '/stripe/checkout');
// }

createCustomerStripe(customer) {
  return this.http.post(environment.apiBaseUrl + '/stripe/customer/', customer);
}

// createSessionStripeCheckout(stripeCheckout) {
//   return this.http.post(environment.apiBaseUrl + '/stripe/checkout', stripeCheckout);
// }

createSessionStripeCheckout(stripeCheckout) {
  return this.http.post(environment.apiBaseUrl + '/online/stripe/onetime/checkout', stripeCheckout);
}



createSessionSuscriptionStripeCheckout(stripeCheckout) {
  return this.http.post(environment.apiBaseUrl + '/online/stripe/checkout/suscription', stripeCheckout);
}

}
