import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../shared/transaction.service';

@Component({
  selector: 'app-home-donor',
  templateUrl: './home-donor.component.html',
  styleUrls: ['./home-donor.component.css']
})
export class HomeDonorComponent implements OnInit {
  private allItems: any[];
  pager: any = {};  
  pagedItems: any[];
  errorRequest:any;
  listTransactionEmpty:any;
  organizationDonations: any;

  constructor(private transactionService: TransactionService) { }

  ngOnInit() {
    this.listOrganizationDonation();
  }
  getData() {
    let id = "123";
    this.transactionService.getTransactionDonatorByOrg(id).subscribe(
      res => {
        if (res['data'].length === 0) {
          this.listTransactionEmpty = true;
        } else {
          this.listTransactionEmpty = false;
          this.allItems = res['data'];
          this.organizationDonations = res['data'];

        }

      },
      err => {
        this.errorRequest = true;
      });

  }

  listOrganizationDonation() {

    this.getData();
    // this.organizationDonations = [{
    //   organisationName: 'LA MOSQUEE DES BLEUETS'
    // },
    // {
    //   organisationName: 'ASSO CULTUREL AIX'
    // },
    // {
    //   organisationName: 'HUMANITY'
    // },

    // ]
  }
}
