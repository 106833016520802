import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from '../../shared/transaction.service';
import { Transaction } from '../../shared/transaction.model';
import { Organization } from '../../shared/organization.model';
import { PagerService } from '../../shared/pager.service';
import { Router } from '@angular/router';

import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
declare var $: any;
@Component({
  selector: 'app-read-transaction-donor',
  templateUrl: './read-transaction-donor.component.html',
  styleUrls: ['./read-transaction-donor.component.css']
})
export class ReadTransactionDonorComponent implements OnInit {

  // @ViewChild('content') content: ElementRef;
  @ViewChild("content", { static: true }) content: ElementRef;

  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  idTransaction: String;
  organization: Organization;
  address: any = {
    city: '',
    line1: '',
    postal_code: '',
    state: '',
  };
  transaction: Transaction;
  card: any = {
    brand: 'unknown.png',
    country: '',
    exp_month: '',
    exp_year: '',
    last4: '',
    network: '',
  };
  nameCard: any;
  constructor(private route: ActivatedRoute, private transactionService: TransactionService, private pagerService: PagerService, private router: Router) {
    this.organization = new Organization();
    this.transaction = new Transaction();
    this.nameCard = '';
  }


  setAdressModal(bflag) {
    if (bflag === false)
      $('.bs-example-modal-lg').modal('hide');
    else {
    
      $('.bs-example-modal-lg').modal('show');
    }


  }

  setAdress() {  

    // this.router.navigate(['/transaction/fiscal/receipt',{"id": this.idTransaction}],
    // {relativeTo: this.route});

    
    this.router.navigate(['dashboard/donor/transaction/fiscal/receipt',this.transaction.id]);
    
  }


  downloadPDF() {



    var element = document.getElementById('content');



    html2canvas(element).then((canvas) => {
      console.log(canvas);
      var imgData = canvas.toDataURL('image/png');

      let doc = new jsPDF();

      var imageHeight = canvas.height * 208 / canvas.width;

      doc.addImage(imgData, 0, 0, 208, imageHeight);

      doc.save('doc.pdf');
    })

    // var elementHandler = {
    //   '#ignorePDF': function (element, renderer) {
    //     return true;
    //   }
    // };

    // var source = window.document.getElementById("content")[0];

    // console.log(doc);

    // doc.fromHTML(
    //   source,
    //   15,
    //   15,
    //   {
    //     'width': 180,'elementHandlers': elementHandler
    //   });



    // // let specialElementHandlers = {
    // //   '#editor': function(element, renderer) {
    // //     return true;
    // //   }
    // // };

    // // console.log(doc);
    // // // doc.addImage('assets/images/orgaLogo/e6kdonationong-logo.png',10,10);
    // doc.addImage('assets/images/orgaLogo/bleuets-logo.png', 'JPEG', 10, 10, 50,20, 'e6kdonation', null, 0)
    // doc.text(this.organization.organisationName, 100, 100);

    // // let content = this.content.nativeElement;

    // // doc.fromHTML(content.innerHTML,15,15, {
    // //   'width': 190,
    // //   'elementHandlers': specialElementHandlers
    // // });

    // doc.save('doc.pdf');
  }

  ngOnInit() {

    let id = 'mail.com';

    this.transactionService.getTransactionByDonator(id).subscribe(
      res => {
        console.log(res);
        if (res['transactions'].length === 0) {
          // this.listTransactionEmpty = true;
          console.log(res);
        } else {
          // this.listTransactionEmpty = false;
          this.allItems = res['transactions'];
          this.idTransaction = id;
          const found = this.allItems.find(x => x._id === this.route.snapshot.paramMap.get('id'));
          const found2 = this.allItems.findIndex(x => x._id === this.route.snapshot.paramMap.get('id'));
          console.log(found);
          console.log(found2);
          let index = found2 + 1;
          this.setPage(index);
        }

      },
      err => {
        // this.errorRequest = true;
      });



  }


  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page, 1);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.transaction = this.pagedItems[0];
    // Call api
    this.getTransactionDonatorById(this.transaction._id);
    // this.card = this.transaction.payment_method_details.card;
    // this.address = this.organization.address;
    // console.log(this.pagedItems[0]);
  }

  getTransactionDonatorById(id) {
    this.transactionService.getTransactionDonatorById(id).subscribe(
      res => {
        this.organization = res['organisation'];
        this.transaction = res['transaction'];
        this.card = this.transaction.payment_method_details.card;
        this.address = this.organization.address;

        // American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.    
        switch (this.card.brand) {
          case 'american Express':
            this.card.brand = '2.png'
            break;
          case 'diners Club':
            this.card.brand = '10.png'
            break;
          case 'discover':
            this.card.brand = '14.png'
            break;
          case 'jcb':
            this.card.brand = '16.png'
            break;
          case 'unionPay':
            this.card.brand = '2.png'
            break;
          case 'visa':
            this.card.brand = '1.png'
            break;
          case 'mastercard':
            this.card.brand = '2.png'
            break;
          case 'maestro':
            this.card.brand = '3.png'
            break;
          case 'paypal':
            this.card.brand = '5.png'
            break;
          case 'unknown':
            this.card.brand = 'unknown.png'
            break;
          default:
            this.card.brand = 'unknown.png'
            break;
        }
      },
      err => {

      });
  }


}
