import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from "@angular/router";
import { UserService } from '../../shared/user.service'
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.css']
})
export class ConfirmRegistrationComponent implements OnInit {

  constructor(private userService:UserService, private router: Router, private route: ActivatedRoute) { }

  
  message: any = {
    title: '',
    baseLine: ''
  };
  linkExpired:any;
  emailDonator: any;  
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  serverErrorMessages:any;
  ngOnInit() {
  
  const token = this.route.snapshot.paramMap.get("token");

  // this.userService.activeUserDonator(token).subscribe(
  //   res => {        
  //     console.log(res);
  //     if(res['expired'] === true) {
  //       this.linkExpired = true;
  //       this.message.title = 'Lien lien a expiré'; 
  //       this.message.baseLine = 'Renouveller votre demande validation de compte en saissisan votre email'; 
  //     } else {
  //       this.linkExpired = false;
  //       this.message.title = 'Votre compte est maintenant actif';
  //       this.message.baseLine = 'Vous pouvez maintenant accéder à votre espace';
  //     }
      
  //   },
  //   err => {
  //     // this.serverErrorMessages = err.error.message;
  //   }
  // );
  }

  sendLinkConfirmationEmail() {

  }

  onSubmit(form: NgForm) {
    
    this.userService.sendLinkConfirmationEmail(form.value).subscribe(
      res => {   

          this.router.navigateByUrl('donor/register/success');        
      },
      err => {
          this.serverErrorMessages = 'Votre email est inconnu';
      }
    );

    
  }


}
