import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl, FormsModule } from '@angular/forms';

import { UserService } from '../../shared/user.service';
import { User } from '../../shared/user.model';
declare var $ :any;

@Component({
  selector: 'app-fiscal-receipt-donator',
  templateUrl: './fiscal-receipt-donator.component.html',
  styleUrls: ['./fiscal-receipt-donator.component.css']
})


export class FiscalReceiptDonatorComponent implements OnInit {
  enabledForm: Boolean = false;
  user: User;
  myReceipt: any = {
    _id: "",
    idStripe: "",
    idOrganisation: "",
    nameOrganisation: "",
    email: "",
    firstName: "",
    lastName: "",
    address: {
      firstName: "",
      lastName: "",
      entreprisename: "",
      address: "",
      code_postal: "",
      city: "",
      state: "",
    },
    receiptNumber: "",
    date: "",
    amount: "",
    idCampaign: "",
    nameCampaign: "",
    isOpenReceipt : {
      isOpen : false,
      isOpenDonator : false,
      isOpenClient : false
    },
    enableReceipt : false

  };
  info : any = {
    messageSucess : "Votre demande de reçu est bien transmise",
    messageSucess2 : "Votre reçu fiscal sera envoyé par mail à cette adresse ",
    messageWaiting : "Transmission de votre demande de reçu",
    messageWaiting2 : "Veuillez patienter svp",
    messageError : "Votre demande de reçu n'est pas transmise",
    messageError2 : "Veuillez essayer ulterieurment svp",
    currentMessage : "",
    currentMessage2: "",
    currentImage : "",
    buttonclose : false
  }

  myGroup: FormGroup;

  organizationName: any = '';
  amount: any = 10;

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) {

    this.user = new User();

    this.myGroup = new FormGroup({
      firstName: new FormControl()
    });


  };

  setDefaultData() {

    this.myReceipt = {};
    this.myReceipt = {
      _id: "",
      idStripe: "",
      idOrganisation: "",
      nameOrganisation: "",
      email: "",
      firstName: "",
      lastName: "",
      entreprisename: "",
      address: "",
      postal_code: "",
      city: "",
      state: "",
      receiptNumber: "",
      date: "",
      amount: "",
      idCampaign: "",
      nameCampaign: "",
      edit: true,
      status: "",
      isOpenReceipt : {
        isOpen : false,
        isOpenDonator : false,
        isOpenClient : false
      },
      enableReceipt : false

    }



    this.setForm(this.myReceipt);
    this.checkData();
  };

  setForm(oReceiptData) {
    this.myGroup = new FormGroup({
      firstName: new FormControl()
    });
  }


  ngOnInit() {

    var idTransaction = this.route.snapshot.paramMap.get('id');
    //idTransaction = "ch_1HcpQMIT3tfUpjoIG7PTYk0m";
    this.setDefaultData();
    this.userService.getDonatorAddress(idTransaction).subscribe(
      res => {
        this.setDefaultAdress(res['data']);
      },
      err => {
        ;
      }
    );



  }
  setDefaultAdress(oData) {

    this.myReceipt.idOrganisation = oData.idOrganisation;
    this.myReceipt.idStripe = oData.idStripe;
    this.myReceipt.email = oData.email;
    this.myReceipt.date = oData.date;
    this.myReceipt.dateShort = oData.dateShort;
    this.myReceipt.amount = oData.amount;
    this.myReceipt.idCampaign = oData.idCampaign;
    this.myReceipt.campaignName = oData.campaignName;
    this.myReceipt.organisationName = oData.organisationName;
    this.myReceipt.address = oData.address;
    this.myReceipt.postal_code = oData.postal_code;
    this.myReceipt.city = oData.city;
    this.myReceipt.state = oData.state;
    this.myReceipt.firstName = oData.firstName;
    this.myReceipt.lastName = oData.lastName;
    this.myReceipt.entreprisename = oData.entreprisename;
    this.myReceipt.receiptNumber = oData.receiptNumber;
    this.myReceipt.status = oData.status;
    this.myReceipt.editmail = oData.editmail;
    this.myReceipt.isOpenReceipt.isOpen = oData.isOpenReceipt.receitpFiscalOpen;
    this.myReceipt.isOpenReceipt.isOpenDonator = oData.isOpenReceipt.receitpFiscalDashboardDonator;
    this.myReceipt.isOpenReceipt.isOpenClient = oData.isOpenReceipt.receitpFiscalDashboardClient;
    if( this.myReceipt.isOpenReceipt.isOpen &&   this.myReceipt.isOpenReceipt.isOpenDonator){      
      this.myReceipt.enableReceipt = oData.enableReceipt; 
    }

    this.myGroup = new FormGroup({
      firstName: new FormControl()
    });

    this.checkData();
  }


  checkData() {
    var errorName = false;
    var errorAddress = false;

    var infoError = {
      error: false,
      errorName: false,
      errorNameMessage: "veuillez saisir votre nom et prénom et/ou la raison social svp",
      errorAddress: false,
      errorAddressMessage: "veuillez saisir votre adresse svp"
    };
    if (this.myReceipt.firstName !== "" && this.myReceipt.lastName === "") {
      infoError.errorName = true;
    }

    if (this.myReceipt.lastName !== "" && this.myReceipt.firstName === "") {
      infoError.errorName = true;
    }

    if (this.myReceipt.lastName !== "" && this.myReceipt.firstName !== "") {
      infoError.errorName = false;
    }

    if (this.myReceipt.lastName === "" && this.myReceipt.firstName === "" && this.myReceipt.entreprisename === "") {
      infoError.errorName = true;
    }

    if (this.myReceipt.lastName === "" && this.myReceipt.firstName === "" && this.myReceipt.entreprisename !== "") {
      infoError.errorName = false;
    }


    if (this.myReceipt.address === "") {
      infoError.errorAddress = true;
    }

    if (this.myReceipt.postal_code === "") {
      infoError.errorAddress = true;
    }

    if (this.myReceipt.city === "") {
      infoError.errorAddress = true;
    }


    if (infoError.errorAddress || infoError.errorName) {
      infoError.error = true;
      this.enabledForm = true;
    } else {
      this.enabledForm = false;
    }

    this.disableEdit();
    return infoError;

  }


  disableEdit() {
    if (this.myReceipt.status === "New" || this.myReceipt.status === "wait" || (this.myReceipt.receiptNumber !== undefined && this.myReceipt.receiptNumber !== null && this.myReceipt.receiptNumber !== "")) {
      this.myReceipt.edit = false;
    } else {
      this.myReceipt.edit = true;
    }




  }

  reloadData() {
    var idTransaction = this.route.snapshot.paramMap.get('id');
    this.userService.getDonatorAddress(idTransaction).subscribe(
      res => {
        this.setDefaultAdress(res['data']);
      },
      err => {
        ;
      }
    );

  }


  async sendReceiptFiscal() {
    var that = this;
    this.waitingRequestReceipt();
    await new Promise(resolve => setTimeout(resolve, 5000));


    //this.successRequestReceipt(that);
   // return true;
     this.userService.getSendReceiptFiscal(this.myReceipt.idStripe,this.myReceipt).subscribe(
       res => {         
         this.successRequestReceipt(res);
       },
       err => {
        this.errorRequestReceipt(err);
       }
     );

  }

  waitingRequestReceipt(){
    this.info.currentMessage = this.info.messageWaiting;
    this.info.currentMessage2 = this.info.messageWaiting2;
    this.info.buttonclose = false;

    this.setPageInfo(true);
   
  }

  successRequestReceipt(data){
    this.info.currentMessage = this.info.messageSucess;
    this.info.currentMessage2 = this.info.messageSucess2 + " " + this.myReceipt.email;
    this.info.buttonclose = true;

    this.reloadData();
    this.setPageInfo(true);
   
  }
  errorRequestReceipt(error){
    this.info.currentMessage = this.info.messageError ;
    this.info.currentMessage2 = this.info.messageError2;
    this.info.buttonclose = true;
    this.setPageInfo(true);
  }


  setPageInfo(bflag) {



  


  }
}
