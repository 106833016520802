import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../shared/organization.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  organizationSearch:any = "";
  onSearch:any;
  response:any = [];
  noOrganization : any;
  
  constructor(private organizationService: OrganizationService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loadExternalScript('assets/js/functions.js')
  }
  

  navBackToHome(){
    this.router.navigate(['/']);
  }


  
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/plugins.min.js');   
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    ;
  }

  onKey(event) {
    const inputValue = event.target.value;
    this.organizationSearch = inputValue;
  }

  onSearchOrganization(){

    this.response = [];
    this.onSearch = true;
    let that = this;

if(this.organizationSearch !== "") {
    this.organizationService.findOrganization({"name":this.organizationSearch}).subscribe(
      res => {
      console.log(res); 
      if(res['organization'].length !== 0) {
        this.noOrganization = false;
        this.response = res['organization'];     
        that.onSearch = false;          
      } else {
        this.onSearch = false;
        this.noOrganization = true;
      }
      
    },
    err => {
        this.onSearch = false;
        console.log(err);
    });
} else {
  this.onSearch = false;
}
  }


}
