import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.css']
})
export class HeaderHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }


  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

}
