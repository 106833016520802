import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router,ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";


export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.css']
})
export class UserResetPasswordComponent implements OnInit {

  public formModel: FormModel = {};
  private siteKey : string = '6LdbDbAZAAAAAEaTv6aWBzblaP6tlaA0iXqKCGp0';
  private siteKey2 : string = '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD';
  key : string = "6LdbDbAZAAAAAHOID_0WA3dnRNh2ZjOURCuPyG7e";

  model = {    
    password: '',
    confirmPassword:''
  };
  equalPassword:any;

  serverErrorMessages: string;
  recaptcha : any[];
  captchaControl2 : boolean;
  emailDonor:any;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  initPassword:any;

  token:any;
  apiFailed = false;
  updatePassword = false;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
     this.token = this.route.snapshot.paramMap.get("token");     
     //Get user by token 
     this.userService.verifTokenUser(this.route.snapshot.paramMap.get("token")).subscribe(
      res => {  
        this.apiFailed = false;        
      },
      err => {        
        this.apiFailed = true;    
        //this.serverErrorMessages = err.error.message;
      }
    );    
  }

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit(form: NgForm) {
    let that  = this;
    if(form.value.password !== form.value.confirmPassword){
      console.log(form);
      this.equalPassword = false;
      return;
    } 
    
    let object = {
      token : this.token,
      form : form.value 
    };

    this.userService.initPasswordDonor(object).subscribe(
      res => {              
       this.updatePassword = true;
       setTimeout(function() {       
        that.router.navigateByUrl('/donor/connect');                    
       }, 3000);  
      },
      err => {     
        this.apiFailed = true;    
      }
    );  
  }

}
