export class Organization {
    organisationName: string;
    address: {
        city: string;
        line1: string;
        postal_code: string;
        state: string;
    };    
}

