import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from '../../shared/user.service'


// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../shared/must-match.validator';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-user-sign-up',
  templateUrl: './user-sign-up.component.html',
  styleUrls: ['./user-sign-up.component.css']
})
export class UserSignUpComponent implements OnInit {


  


  public formModel: FormModel = {};
  private siteKey : string = '6LdbDbAZAAAAAEaTv6aWBzblaP6tlaA0iXqKCGp0';
  private siteKey2 : string = '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD';
  key : string = "6LdbDbAZAAAAAHOID_0WA3dnRNh2ZjOURCuPyG7e";

  model = {
    email: '',
    password: '',
    confirmPassword:''
  };

  equalPassword:any;

  serverErrorMessages: string;
  recaptcha : any[];
  captchaControl2 : boolean;

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(private userService:UserService, private router: Router) { }

  ngOnInit() {

  }

  // convenience getter for easy access to form fields
  

  onReset() {
    
    
}


onSubmit(form: NgForm) {

  if(form.value.password !== form.value.confirmPassword){
    console.log(form);
    this.equalPassword = false;
    return;
  }  
  this.equalPassword = true;
  this.userService.registerDonor(form.value).subscribe(
    res => {              
      this.router.navigate(['/donor/connect/confirm/code/' + res['token']]);         
    },
    err => {      
      if (err.status === 422) {
        this.serverErrorMessages = err.error.join('<br/>');
      } else {
        this.serverErrorMessages = 'Something went wrong.Please contact admin.';
      }
    }
  )
  
  
}

  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
