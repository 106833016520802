import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  template: any;
  organizationName:any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {


// CALL SERVICES FOR GET NAME AND TEMPLATE ORGANIZATION
  this.organizationName = this.route.snapshot.paramMap.get('id');
console.log(this.organizationName);
    this.template = {
      nameColor: '',
      // color: '#204468',
      color: '#0F84B6',
      colorActive: '#0F84B6',
      // color: '#7a67ad',
      // colorActive: '#7a67ad',
    };
  }

  // public loadExternalScript(url: string) {
  //   const body = <HTMLDivElement> document.body;
  //   const script = document.createElement('script');
  //   script.innerHTML = '';
  //   script.src = url;
  //   script.async = true;
  //   script.defer = true;
  //   body.appendChild(script);
  // }


  // ngAfterViewInit() {
  //   // this.loadExternalScript('assets/js/jquery.js');
  //   // this.loadExternalScript('assets/js/jquery.calendario.js');
  //   // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
  //   this.loadExternalScript('assets/js/functions.js');
  // }

}
