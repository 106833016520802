import { Component, OnInit } from '@angular/core';
import { StripeCheckout } from '../../shared/stripeCheckout.model';
import { CampaignService } from '../../shared/campaign.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule  } from '@angular/forms';
import { environment } from '../../../environments/environment';
declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-donor-information',
  templateUrl: './donor-information.component.html',
  styleUrls: ['./donor-information.component.css']
})
export class DonorInformationComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;

  isForm: boolean;
  isLoading: boolean;
  error: boolean;
  stripeCheckout: StripeCheckout;
  organizationName: any;

  constructor(private campaignService: CampaignService, private route: ActivatedRoute,
              private router: Router, private formBuilder: FormBuilder) {
    this.stripeCheckout = new StripeCheckout();
    // this.stripeCheckout.title = decodeURIComponent(this.route.snapshot.paramMap.get('title'));
    // this.stripeCheckout.description = decodeURIComponent(this.route.snapshot.paramMap.get('description'));

    // this.stripeCheckout.title = this.route.snapshot.paramMap.get('title');
    // this.stripeCheckout.description = this.route.snapshot.paramMap.get('description');
    // // this.stripeCheckout.logo = 'https://www.e6kdonation.com/wp-content/uploads/2019/03/' + this.route.snapshot.paramMap.get('logo');
    // this.stripeCheckout.logo = this.route.snapshot.paramMap.get('logo');
    // this.stripeCheckout.icon = this.route.snapshot.paramMap.get('icon');
    // this.stripeCheckout.organization = this.route.snapshot.paramMap.get('organisation');
    // this.stripeCheckout.stripe = this.route.snapshot.paramMap.get('stripe');
    // this.stripeCheckout.campaign = this.route.snapshot.paramMap.get('campaign');
    // this.stripeCheckout.amount = this.route.snapshot.paramMap.get('amount');

    // this.organizationName = this.route.snapshot.paramMap.get('asso');
    // console.log(this.organizationName);

    this.registerForm = this.formBuilder.group({
         donorInformation: ['', Validators.required],
         email: ['', [Validators.required, Validators.email]],
    });

  }

  ngOnInit() {


    this.campaignService.organizationByIdAndCammpaignById(this.route.snapshot.paramMap.get('organizationId'),this.route.snapshot.paramMap.get('campaignId')).subscribe(
      res => {
        this.stripeCheckout.campaign = res['campaign']._id
        this.stripeCheckout.title = res['campaign'].title;
        this.stripeCheckout.description = res['campaign'].description;
        this.stripeCheckout.icon = res['campaign'].icon;
        // this.stripeCheckout.logo = 'https://www.e6kdonation.com/wp-content/uploads/2019/03/' + this.route.snapshot.paramMap.get('logo');
        // this.stripeCheckout.logo = this.route.snapshot.paramMap.get('logo');
        this.stripeCheckout.organization = res['organization']._id;
        this.stripeCheckout.stripe = res['organization'].id_connected_account;

        this.stripeCheckout.cancel_url = environment.apiBaseUrlStripe + '/don/' + res['organization'].linkDonation;
        this.stripeCheckout.success_url = environment.apiBaseUrlStripe + '/don/' + res['organization'].linkDonation +'/sucess/' + res['organization'].linkDonation;
        console.log(this.stripeCheckout.success_url);
        this.organizationName = res['organization'].linkDonation;

          console.log(res);
      },
      err => {
          // console.log(err);
      });


    // this.organizationExist(this.stripeCheckout.organization, this.stripeCheckout.stripe, this.organizationName);
    // this.campaignExist(this.stripeCheckout.campaign, this.stripeCheckout.title, this.stripeCheckout.description);
    this.isForm = true;
    this.isLoading = false;
    this.error = false;
  }

  organizationExist(id, stripe, organization) {

    this.campaignService.organizationExist(id, stripe, organization).subscribe(
      res => {
          //
          if(res['organizationExist'] === false) {
            this.router.navigate(['don/' + this.organizationName ]);
          }
      },
      err => {
          // console.log(err);
      });

  }

  campaignExist(campaign, title, description) {

    this.campaignService.campaignExist(campaign, title, description).subscribe(
      res => {
        //
        if(res['campaignExist'] === false) {
          this.router.navigate(['don/' + this.organizationName ]);
        }
    },
    err => {

    });
  }


    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    console.log(this.registerForm.invalid);
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    const timestamp = Date.now();

    const objectCustomer = {
        customer : this.registerForm.value,
        metadata : this.stripeCheckout,
        // timestamp : periodStart,
        periodStart : timestamp,
        interval : 'monthly'
    };




    this.campaignService.createCustomerStripe(objectCustomer).subscribe(
      res => {
          //
          console.log(res);
          // call to create checkout session
          const customer = res['customer']['id'];

          this.createStripeSuscriptionSessionCheckout(customer, this.stripeCheckout.stripe, this.organizationName);
      },
      err => {
          this.error = true;
          this.isLoading = false;
          // console.log(err);
      });

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
}


createStripeSuscriptionSessionCheckout(customer, stripeAccountConnected, organizationName) {
  const stripeCheckout = {
    customer: customer,
    stripeAccountConnected: stripeAccountConnected,
    organizationName: organizationName
  }
  this.campaignService.createSessionSuscriptionStripeCheckout(stripeCheckout).subscribe(
    res => {
      console.log(res['session'].id);
      const sessionId = res['session'].id;
      // this.stripe = Stripe('pk_test_your_key');
      const stripe = Stripe('pk_test_KmiSL35NVxX8h8qrWQWm5L0h', {
        stripeAccount: stripeAccountConnected
      });
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        this.error = true;
        this.isLoading = false;
      });
      // document.location.href = 'https://e6kdonation.com/checkout/stripe.html?stripe=pk_test_KmiSL35NVxX8h8qrWQWm5L0h&stripeaccount=acct_1GBIHWBRuv9WoXh1&id='+sessionId;
    },
    err => {
        this.error = true;
        this.isLoading = false;
        // console.log(err);
    });
}

onReset() {
  this.submitted = false;
  this.registerForm.reset();
}

  createStripeSuscriptionSessionCheckoutOld(customer) {
    this.stripeCheckout.amount = Number(this.stripeCheckout.amount * 100);
    this.error = false;
    this.isLoading = true;
    this.isForm = false;

    //Create customer
    this.campaignService.createCustomerStripe(this.stripeCheckout).subscribe(
      res => {
        //Create checkout session
      },
      err => {
          this.error = true;
          this.isLoading = false;
          // console.log(err);
      });

      const objectSuscription = {
        customer : '',
        stripe : ''
    };


    this.campaignService.createSessionSuscriptionStripeCheckout(this.stripeCheckout).subscribe(
      res => {
        console.log(res['session'].id);
        const sessionId = res['session'].id;
        // this.stripe = Stripe('pk_test_your_key');
        const stripe = Stripe('pk_test_KmiSL35NVxX8h8qrWQWm5L0h', {
          stripeAccount: this.stripeCheckout.stripe
        });
        stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          this.error = true;
          this.isLoading = false;
        });
        // document.location.href = 'https://e6kdonation.com/checkout/stripe.html?stripe=pk_test_KmiSL35NVxX8h8qrWQWm5L0h&stripeaccount=acct_1GBIHWBRuv9WoXh1&id='+sessionId;
      },
      err => {
          this.error = true;
          this.isLoading = false;
          // console.log(err);
      });
  }


}
